<template>
  <div class="business">
    <v-container class="position-relative" style="z-index: 1">
      <v-row>
        <v-col class="mt-6 ml-md-16 ml-0">
          <h1 class="business-title"><span class="business-title__first">Бизнес</span> <br v-if="windowWidth > 959"><span class="business-title__last"> с Атоми</span></h1>
          <p class="business-subtitle mt-sm-4 mt-2">Атоми предоставляет каждому возможность зарабатывать<br v-if="windowWidth > 959"> без обязательных финансовых вложений и опыта работы в бизнесе.</p>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="px-0 position-relative" style="z-index: 1">
      <div class="business-second py-4">
        <v-container>
          <v-row>
            <v-col cols="12" lg="9" xl="6">
              <h2 class="business-second-title">Как зарабатывать в Атоми?</h2>
              <p class="business-second-subtitle mt-2 mb-0">Вы просто рекомендуете товар Атоми другим людям на
                основании личного опыта и положительного отзыва, тем самым привлекаете новых клиентов и партнеров,
                расширяя свою структуру и клиентскую базу, в итоге получаете свои выплаты и премии.
                За несколько месяцев вы можете выйти на стабильный доход.</p>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-container>
    <v-container class="position-relative" style="z-index: 1">
      <v-row>
        <v-col cols="12" class="mt-lg-8 mt-6 mb-lg-6">
          <h2 class="business-third__title">Почему Атоми?</h2>
        </v-col>
        <v-col cols="12" md="4">
          <v-card class="business-card" flat>
            <v-card-text>
              <v-img class="business-card__image" contain :src="require('@/assets/icons/business-icon1.png')"></v-img>
              <h3 class="business-card__title">ВЫСОКОЕ КАЧЕСТВО ПРОДУКЦИИ</h3>
              <p class="business-card__text">Вся продукция Атоми из натуральных ингредиентов премиального качества, что обеспечивает ее востребованность во всем мире</p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card class="business-card" flat>
            <v-card-text>
              <v-img class="business-card__image" contain :src="require('@/assets/icons/business-icon2.png')"></v-img>
              <h3 class="business-card__title">ШИРОКИЙ АССОРТИМЕНТ</h3>
              <p class="business-card__text">Атоми представляет широкую линейку востребованной во всем мире разнообразной продукции, ассортимент которой постоянно расширяется</p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card class="business-card" flat>
            <v-card-text>
              <v-img class="business-card__image" contain :src="require('@/assets/icons/business-icon3.png')"></v-img>
              <h3 class="business-card__title">ГЛОБАЛЬНАЯ КОМПАНИЯ</h3>
              <p class="business-card__text">Компания представлена более чем в 50 странах мира. Совокупный ежегодный оборот составляет более 2 млрд $</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid id="advantages">
      <v-row>
        <v-col cols="12">
          <v-img :src="require('@/assets/images/business-atom2.svg')" width="100%" height="auto"></v-img>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="position-relative" style="z-index: 1">
      <v-row>
        <v-col cols="12" md="10" offset-md="1" class="mt-10">
          <div class="business-video__movie">
            <vue-plyr>
              <video playsinline crossorigin controls width="100%" :poster="require('@/assets/images/business-video__preview_2.jpg')">
                <source :src="getVideo()" type="video/mp4" />
              </video>
            </vue-plyr>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: ['windowWidth'],
  name: "BusinessView",
  methods: {
    getVideo() {
      return process.env.VUE_APP_API_URL + '/api/image/getVideo/' + 'business-video.mp4'
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (to.hash === '#advantages') {
          setTimeout(() => {
            const scrollTo = document.querySelector('#advantages').offsetTop
            window.scrollTo({ top: scrollTo - 30, behavior: 'smooth'});
          }, 500)
        }
      }
    },
  }
}
</script>

<style scoped lang="sass">
.business
  &-title
    color: #018ABE
    font-size: 56px
    font-family: 'Roboto', sans-serif
    line-height: 1.1
    &__last
      margin-left: 110px
  &-subtitle
    font-size: 20px
    font-family: 'Roboto', sans-serif
  &-second
    margin-top: 160px
    background: linear-gradient(90deg, rgba(1, 138, 190, 0.71) 0%, rgba(180, 228, 243, 0) 100%)
    &-title
      font-size: 32px
      font-family: 'Roboto', sans-serif
      line-height: 1.1
    &-subtitle
      font-size: 20px
      font-family: 'Roboto', sans-serif
  &-third
    &__title
      font-size: 32px
      font-family: 'Roboto', sans-serif
      line-height: 1.1
  &-card
    background: none
    box-shadow: none
    &__image
      height: 134px
    &__title
      color: #000000
      margin-top: 24px
      font-size: 20px
      font-family: Roboto, sans-serif
      font-weight: bold
      text-align: center
    &__text
      color: #000000
      margin-top: 16px
      font-size: 20px
      font-family: Roboto, sans-serif
      text-align: center
@media (max-width: 1263.99px)
  .business
    &-title
      font-size: 46px
      &__last
        margin-left: 80px
    &-subtitle
      font-size: 20px
    &-second
      margin-top: 130px
      &-title
        font-size: 30px
      &-subtitle
        font-size: 20px
    &-third
      &__title
        font-size: 30px
    &-card
      &__image
        height: 120px
      &__title
        margin-top: 20px
        font-size: 18px
      &__text
        margin-top: 12px
        font-size: 16px
@media (max-width: 959.99px)
  .business
    &-title
      font-size: 40px
      &__last
        margin-left: 0
    &-subtitle
      font-size: 20px
    &-second
      margin-top: 90px
      &-title
        font-size: 28px
      &-subtitle
        font-size: 20px
    &-third
      &__title
        font-size: 28px
    &-card
      &__image
        height: 120px
      &__title
        margin-top: 20px
        font-size: 20px
      &__text
        margin-top: 12px
        font-size: 18px
@media (max-width: 599.98px)
  .business
    &-title
      font-size: 36px
      &__last
        margin-left: 0
    &-subtitle
      font-size: 18px
      outline: none
      border: none
    &-second
      margin-top: 80px
      &-title
        font-size: 26px
      &-subtitle
        font-size: 18px
    &-third
      &__title
        font-size: 28px
    &-card
      &__image
        height: 120px
      &__title
        margin-top: 20px
        font-size: 20px
      &__text
        margin-top: 12px
        font-size: 18px
</style>
